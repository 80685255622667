import React from 'react';

import Layout from '../components/Layout';
import RestorePassword from '../components/RestorePassword';

const RestorePass = () => (
  <Layout>
    <RestorePassword />
  </Layout>
);

export default RestorePass;
